import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translation resources
export const resources = {
  en: {
    translation: {
      metaContent: {
        home: {
          title: "Wellinyoga - Hatha vinyasa yoga in Tampere",
          description:
            "Yoga in Tampere centrum by Dennis Wellingtone. Hatha Vinyasa yoga: weekly classes, private classes, couple classes, prenatal yoga and small group yoga.",
        },
        services: {
          title: "Yoga in weekly and private classes, prenatal & pair yoga",
          description:
            "Yoga weekly classes dynamic and deep-stretching hatha flow, and yin. Private and tailored classes for individuals and groups & prenatal and partner yoga.",
        },
        schedule: {
          title: "Yoga weekly classes schedule and class contents",
          description:
            "Yoga weekly classes schedule from Monday to Saturday. Includes the contents of the different types of classes explained.",
        },
        pricing: {
          title: "Price list of yoga classes | Wellinyoga",
          description:
            "Price list for different types of classes, private classes and passes you can purchase.",
        },
        about: {
          title: "Get to know yoga teacher Dennis Wellingtone",
          description:
            "Get to know the Wellinyoga founder and teacher Dennis Wellingtone, and his story with yoga.",
        },
        whyMe: {
          title: "Why you should choose Wellinyoga",
          description:
            "Wellinyoga is an exceptional yoga studio and an experience, learn more as to why you should choose Wellinyoga.",
        },
        faq: {
          title: "Frequently Asked Questions (and answers) | Wellinyoga",
          description:
            "The most frequently asked questions and their answers to provide you clarity. Send me a message if you have something in your mind :)",
        },
        testimonials: {
          title: "Testimonials and said about Wellinyoga",
          description:
            "A couple short highlights from how people recommend and experience Wellinyoga.",
        },
        instagramFeed: {
          title: "@wellinyoga latest posts in instagram",
          description:
            "See the latest posts of Wellinyoga's instagram and remember to follow @wellinyoga.",
        },
        contact: {
          title: "Contact information, address and email | Wellinyoga",
          description:
            "Find all the important contact information for Wellinyoga. You can contact me via WhatsApp, email, or a regular text message.",
        },
      },
      siteTitle: "Wellinyoga",
      pageTitle: "Yoga in Tampere near Pyynikintori",
      pageSubtitle:
        "I offer individual and assisted yoga classes based on Hatha Vinyasa. Low threshold private lessons, maternity yoga, couples yoga, small group yoga for your own group. Weekly yoga classes in a small group (max 9 participants).",
      phone: "Phone",
      email: "Email",
      showMore: "Show more",
      readMore: "Read more",
      showLess: "Show less",
      inGoogleMaps: "Wellinyoga in Google Maps",
      bookInVello: "Book in Vello",
      openByScheduleAndByAppointment: "Open as in schedule and by appointment.",
      theServicesIOffer: "What I offer",
      yogaTitle: "Yoga",
      massageTitle: "Massage",
      FAQPageTitle: "Frequently asked questions",
      intensityExplanation:
        "Intensity levels: 1 light, 2 mid-intense, 3 physically intense workout",
      intensity: "Intensity level",
      footerCTA: "Feel free to message me! :)",
      paymentOptions: "Payment options",
      somethingWentWrongTitle: "It will work soon!",
      somethingWentWrongDescription:
        "Oops. There should be content here, but it got lost on its way. Refresh the page to try again :')",
      fetchingData: "Fetching data...",
      weekdays: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
      },
      paths: {
        home: "/home",
        services: "/yoga-classes-and-massage",
        schedule: "/yoga-class-schedule",
        pricing: "/yoga-classes-and-massage-pricing",
        about: "/get-to-know-me",
        whyMe: "/why-choose-wellinyoga",
        faq: "/frequently-asked-questions",
        testimonials: "/testimonials",
        instagramFeed: "/wellinyoga-instagram",
        contact: "/contact-information",
      },
      pageTitles: {
        home: "Home",
        services: "Services",
        schedule: "Schedule",
        pricing: "Pricing",
        about: "Get to know me",
        whyMe: "Why choose Wellinyoga",
        faq: "FAQ",
        testimonials: "Testimonials",
        instagramFeed: "Follow @wellinyoga in instagram",
        contact: "Contact information",
      },
      ariaLabels: {
        goTo: "Go to",
        mainNavigation: "Main Navigation",
        mainNavigationMobile: "Main Navigation mobile",
        mainNavigationMobileLinks: "Main Navigation links",
        changeLanguage: "Vaihda kieli suomeksi",
        toHome: "To homepage",
        homePageWhatsappAndSocialMediaLinks:
          "Links to homepage, WhatsApp, and social medias",
        sendMeWhatsApp: "Send me a message in WhatsApp. Opens WhatsApp app.",
        contactMeViaPhone: "Contact me via phone, preferably through WhatsApp.",
        seeMyInstagram: "Visit Wellinyoga's profile in Instagram.",
        seeMyFacebook: "Visit Wellinyoga's page in Facebook.",
        openGoogleMapsTo: "Open Google Maps to Wellinyoga at",
        openEmailAppTo: "Open email app to send a message to",
        bookInVello: "Open Vello site to book your yoga classes.",
        intensityExplanationToggle:
          "Show and hide intensity levels' explanation.",
        showTestimonialAt: "Show testimonial number",
        showPreviousTestimonial: "Show previous testimonial",
        showNextTestimonial: "Show next testimonial",
        testimonialText: "Testimonial text",
        testimonialAuthor: "Testimonial author's name",
        wellinyogaStreetAddress: "Wellinyoga's street address",
        loadingOverlay: "Navigating to section and loading content",
        paymentCard: "Card payments at the studio",
        paymentVello:
          "Bookings and paying in advance through Vello.fi/wellinyoga",
        paymentEdenred: "Pay using Edenred -benefit at the studio",
        paymentSmartumPay: "Pay using Smartum Pay -benefit at the studio",
        paymentEpassi: "Pay using Epassi -benefit at the studio",
        paymentOptions: "Payment options",
      },
    },
  },
  fi: {
    translation: {
      metaContent: {
        home: {
          title: "Wellinyoga - Hatha vinyasa joogaa Tampereella",
          description:
            "Joogaa Tampereen keskustassa Dennis Wellingtonen ohjaamana. Hatha Vinyasa joogaa: viikkotunnit, yksityistunnit, paritunnit, äitiysjooga ja pienryhmäjooga.",
        },
        services: {
          title: "Joogan viikko- ja yksityistunnit sekä äitiys- ja parijooga",
          description:
            "Joogan viikkotunneilla dynaamista ja syvävenyttävää hatha flowta ja yiniä. Yksityiset ja räätälöidyt tunnit yksilöille ja ryhmille, äitiys- ja parijooga.",
        },
        schedule: {
          title: "Joogan viikkotuntien aikataulu ja tuntien sisältö",
          description:
            "Joogan viikkotuntien aikataulu maanantaista lauantaihin. Sisältää erityylisten tuntien teemat ja pienet selitykset tuntien tarkemmasta sisällöstä.",
        },
        pricing: {
          title: "Joogatuntien hinnasto | Wellinyoga",
          description:
            "Hinnasto erityyppisille tunneille, yksityistunneille ja sarjakorteille joita voit ostaa",
        },
        about: {
          title: "Tutustu joogaohjaaja Dennis Wellingtoneen",
          description:
            "Tutustu Wellinyogan perustajaan sekä joogaohjaaja Dennis Wellingtoneen ja hänen tarinaansa joogan parissa.",
        },
        whyMe: {
          title: "Miksi sinun kannattaa valita Wellinyoga",
          description:
            "Wellinyoga on erinomainen joogastudio ja kokemus, lue lisää jotta ymmärrät miksi sinunkin kannattaa valita Wellinyoga.",
        },
        faq: {
          title: "Usein Kysytyt Kysymykset (ja vastaukset) | Wellinyoga",
          description:
            "Useimmiten kysytyt kysymykset ja niiden vastaukset antamaan sinulle selkeyttä. Laita minulle rohkeasti viestiä jos mietit jotakin :)",
        },
        testimonials: {
          title: "Suosituksia ja sanottua Wellinyogasta",
          description:
            "Muutamia lyhyitä nostoja siitä miten ihmiset suosittelevat ja kokevat Wellinyogan.",
        },
        instagramFeed: {
          title: "@wellinyoga viimeisimmät postaukset instagramissa",
          description:
            "Katso viimeisimmät Wellinyogan instagram postaukset ja muista seurata @wellinyoga.",
        },
        contact: {
          title: "Yhteystiedot, osoite ja sähköposti | Wellinyoga",
          description:
            "Täältä löydät Wellinyogan kaikki tärkeät yhteystiedot. Voit ottaa yhteyttä WhatsAppilla, sähköpostilla tai tekstiviestillä.",
        },
      },
      siteTitle: "Wellinyoga",
      pageTitle: "Joogaa Tampereen Pyynikintorin tuntumassa",
      pageSubtitle:
        "Tarjoan Hatha Vinyasa- pohjaisia yksilöllisiä ja avustettuja joogatunteja. Matalan kynnyksen yksityistunnit, äitiysjooga, parijooga, pienryhmäjooga omalle porukalle. Joogan viikkotunnit pienryhmässä (max 9 osallistujaa).",
      phone: "Puhelin",
      email: "Sähköposti",
      showMore: "Näytä lisää",
      readMore: "Lue lisää",
      showLess: "Näytä vähemmän",
      inGoogleMaps: "Wellinyoga Google Mapsissa",
      bookInVello: "Varaa Vellossa",
      openByScheduleAndByAppointment: "Auki viikkotuntien aikaan ja sovitusti.",
      theServicesIOffer: "Tarjoamani palvelut",
      yogaTitle: "Jooga",
      massageTitle: "Hieronta",
      FAQPageTitle: "Usein kysytyt kysymykset",
      intensityExplanation:
        "Intensiteetin tasot: 1 kevyt, 2 keskirankka, 3 fyysisesti haastava tunti",
      intensity: "Intensiteetti",
      footerCTA: "Laita minulle rohkeasti viestiä! :)",
      paymentOptions: "Maksuvaihtoehdot",
      somethingWentWrongTitle: "Kyllä se korjaantuu!",
      somethingWentWrongDescription:
        "Hups. Tässä pitäisi olla sisältöä, mutta se hävisi matkalle. Kokeile päivittää sivu uudelleen :')",
      fetchingData: "Haetaan tietoja...",
      weekdays: {
        monday: "Maanantai",
        tuesday: "Tiistai",
        wednesday: "Keskiviikko",
        thursday: "Torstai",
        friday: "Perjantai",
        saturday: "Lauantai",
        sunday: "Sunnuntai",
      },
      paths: {
        home: "/",
        services: "/joogatunnit-ja-hieronta",
        schedule: "/joogatuntien-aikataulu",
        pricing: "/joogatunnit-ja-hieronta-hinnasto",
        about: "/tutustu-minuun",
        whyMe: "/miksi-valita-wellinyoga",
        faq: "/usein-kysytyt-kysymykset",
        testimonials: "/suosittelut",
        instagramFeed: "/wellinyoga-instagramissa",
        contact: "/yhteystiedot",
      },
      pageTitles: {
        home: "Etusivu",
        services: "Palvelut",
        schedule: "Aikataulu",
        pricing: "Hinnasto",
        about: "Tutustu minuun",
        whyMe: "Miksi valita Wellinyoga",
        faq: "UKK",
        testimonials: "Suosittelut",
        instagramFeed: "Seuraa @wellinyoga instagramissa",
        contact: "Yhteystiedot",
      },
      ariaLabels: {
        goTo: "Siirry kohtaan",
        mainNavigation: "Päänavigaatio",
        mainNavigationMobile: "Päänavigaatio mobiili",
        mainNavigationMobileLinks: "Päänavigaation linkit",
        changeLanguage: "Change language to English",
        toHome: "Etusivulle",
        homePageWhatsappAndSocialMediaLinks:
          "Linkit etusivulle, WhatsAppiin ja sosiaaliseen mediaan",
        sendMeWhatsApp:
          "Lähetä minulle viesti WhatsAppissa. Avaa sovelluksen WhatsApp.",
        contactMeViaPhone:
          "Ota minuun yhteyttä puhelimitse, mieluusti WhatsAppilla.",
        seeMyInstagram: "Vieraile Wellinyogan profiilissa Instagramissa.",
        seeMyFacebook: "Vieraile Wellinyogan Facebook -sivulla.",
        openGoogleMapsTo: "Avaa Google Maps Wellinyogan osoitteeseen",
        openEmailAppTo: "Avaa sähköposti lähettääksesi viestiä osoitteeseen",
        bookInVello: "Avaa Vello tehdäksesi varauksia joogatunneille.",
        intensityExplanationToggle:
          "Näytä ja piilota intensiteettitasojen selitys.",
        showTestimonialAt: "Näytä suositus numero",
        showPreviousTestimonial: "Näytä edellinen suositus",
        showNextTestimonial: "Näytä seuraava suositus",
        testimonialText: "Suosittelun teksti",
        testimonialAuthor: "Suosittelun antajan nimi",
        wellinyogaStreetAddress: "Wellinyogan katuosoite",
        loadingOverlay: "Navigoi osioon ja lataa sisältöä",
        paymentCard: "Kortilla maksaminen paikan päällä",
        paymentVello: "Varaukset ja maksaminen Vello.fi/wellinyoga -kautta",
        paymentEdenred: "Edenred -edulla maksaminen paikan päällä",
        paymentSmartumPay: "Smartum Pay -edulla maksaminen paikan päällä",
        paymentEpassi: "Epassi -edulla maksaminen paikan päällä",
        paymentOptions: "Maksuvaihtoehdot",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fi", // Default language
  fallbackLng: "en", // If language not found, fallback to English
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS
  },
});

export default i18n;
