import React from "react";
import { Box, useTheme } from "@mui/material";
import { Hotspot } from "../shared-types";
import { client } from "../utils/sanityUtils";
import { imageNotFoundFallback } from "../constants";
import imageUrlBuilder from "@sanity/image-url";

interface LazyLoadBackgroundProps {
  alt?: string;
  imgURL?: string;
  imgSize?: { height?: number; width?: number };
  hotspot?: Hotspot;
  children?: React.ReactNode;
  sx?: object;
}

const LazyLoadBackground: React.FC<LazyLoadBackgroundProps> = ({
  alt = "Image",
  imgURL,
  hotspot,
  children,
  imgSize,
  sx,
}) => {
  const theme = useTheme();

  // Initialize the image URL builder with your client
  const builder = imageUrlBuilder(client);

  // Function to get image URL using the builder
  const urlFor = (source: string) => {
    if (source === imageNotFoundFallback) {
      return imageNotFoundFallback;
    }
    if (imgSize) {
      // If both were defined in props
      if (imgSize.height && imgSize.width) {
        const betterQualityHeight = Math.round(imgSize.height * 1.5);
        const betterQualityWidth = Math.round(imgSize.width * 1.5);

        return builder
          .image(source)
          .height(betterQualityHeight)
          .width(betterQualityWidth)
          .url();
      }
      // If height was defined in props
      if (imgSize.height) {
        const betterQualityHeight = Math.floor(imgSize.height * 1.5);
        return builder.image(source).height(betterQualityHeight).url();
      }

      // If width was defined in props
      if (imgSize.width) {
        const betterQualityWidth = Math.floor(imgSize.width * 1.5);
        return builder.image(source).height(betterQualityWidth).url();
      }
    }
    return builder.image(source).url();
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.beige.background,
        ...sx,
      }}
    >
      {imgURL && (
        <img
          src={urlFor(imgURL)}
          alt={alt}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: hotspot
              ? `${hotspot.x * 100}% ${hotspot.y * 100}%`
              : "center", // Set hotspot position
          }}
        />
      )}
      {children}
    </Box>
  );
};

export default LazyLoadBackground;
