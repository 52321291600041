import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import images from "../utils/images";
import { SanityImage } from "../shared-types";
import { client, fetchInstagramPosts } from "../utils/sanityUtils";
import { imageNotFoundFallback } from "../constants";
import imageUrlBuilder from "@sanity/image-url";

const InstagramFeed: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTinyMobile = useMediaQuery("(max-width: 400px)");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const fallbackData = Array.from({ length: 4 }, () => ({
    imageUrl: imageNotFoundFallback,
    alt: "Something didn't work somewhere",
  }));
  const [data, setData] = useState<SanityImage[]>(fallbackData);
  const imageMaxSize = isMobile ? 150 : 200;

  // Initialize the image URL builder with your client
  const builder = imageUrlBuilder(client);

  // Function to get image URL using the builder
  const urlFor = (source: string) => {
    return source !== imageNotFoundFallback
      ? builder
          .image(source)
          .width(2 * imageMaxSize)
          .url()
      : imageNotFoundFallback;
  };

  // Fetch data when language changes
  useEffect(() => {
    fetchInstagramPosts().then((dataFromSanity: SanityImage[]) => {
      if (dataFromSanity[0].imageUrl) {
        setData([]); // reset fallback data
        setData(dataFromSanity);
      }
    });
  }, []);

  // Use fallback elements with regular backgroundColor if no data found
  const postsAsElements = () => {
    const boxProps = {
      component: "a",
      href: "https://www.instagram.com/wellinyoga",
      target: "_blank",
      rel: "noopener noreferrer",
      sx: (theme: Theme) => ({
        display: "block",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.beige.light,
        border: `2px solid ${theme.palette.beige.background}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "8px",
        cursor: "pointer",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
        },
        boxShadow: `0 1px 5px ${theme.palette.overlay.dark}`,
      }),
    };

    const gridSxProps = {
      width: imageMaxSize,
      height: imageMaxSize,
    };

    if (data) {
      return data.map((post, index) => {
        return index < 4 ? (
          <Grid item key={post.imageUrl + index} sx={gridSxProps}>
            <Box
              aria-label={t("ariaLabels.seeMyInstagram")}
              {...boxProps}
              sx={{
                ...boxProps.sx(theme), // Spread existing styles from boxProps
                backgroundImage: `url(${post.imageUrl})`,
              }}
            >
              <img
                src={urlFor(post.imageUrl)}
                alt={post.alt}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        ) : undefined;
      });
    } else {
      const placeholders = [];
      for (let i = 0; i < 4; i++) {
        placeholders.push(
          <Grid
            item
            key={"Wellinyoga-instagram-placeholder-" + i}
            sx={gridSxProps}
          >
            <Box aria-label={t("ariaLabels.seeMyInstagram")} {...boxProps} />
          </Grid>,
        );
      }
      return placeholders;
    }
  };

  return (
    <Box
      className="instagram-feed"
      sx={{
        paddingTop: { xs: 24 },
        paddingBottom: { xs: 8, md: 0 },
        minHeight: { xs: "500px" },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${images.layeredWaves2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Typography
        variant="h4"
        paddingBottom={6}
        color={theme.palette.primary.contrastText}
        sx={{
          fontFamily: "Dahlia, Arial, sans-serif",
          textAlign: "center",
          maxWidth: "90%",
        }}
      >
        {t("pageTitles.instagramFeed")}
      </Typography>

      {/* Instagram Post Thumbnails */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        width={isTinyMobile ? { xs: "200px" } : { xs: "400px", md: "initial" }}
      >
        {postsAsElements()}
      </Grid>
    </Box>
  );
};

export default InstagramFeed;
