import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  useTheme,
  Button,
  IconButton,
  FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SpeedIcon from "@mui/icons-material/Speed";
import TimerIcon from "@mui/icons-material/Timer";
import PeopleIcon from "@mui/icons-material/People";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LazyLoadBackground from "../LazyLoadBackground";
import { Service } from "../../shared-types";
import { fetchServices } from "../../utils/sanityUtils";
import { imageNotFoundFallback } from "../../constants";

const ServicesList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [activeHelpTitle, setActiveHelpTitle] = useState<string | null>(null); // Track which service's help is open
  const [data, setData] = useState<Service[]>([
    {
      type: "yoga",
      serviceTitle: t("fetchingData"),
      description: t("fetchingData"),
      duration: t("fetchingData"),
      intensity: t("fetchingData"),
      maxAttendees: t("fetchingData"),
      image: {
        imageUrl: imageNotFoundFallback,
        alt: "Something didn't work somewhere",
      },
    },
  ]);
  const [servicesWithCategories, setServicesWithCategories] =
    useState<boolean>(false);

  // Fetch class explanations when language changes
  useEffect(() => {
    fetchServices(i18n.language).then((dataFromSanity: Service[]) => {
      setData(dataFromSanity);
      renderTitle(dataFromSanity);
    });
  }, [i18n.language]);

  // Check whether there are both yogas and massages in the data
  const renderTitle = (data: Service[]) => {
    const types = new Set(data.map((item) => item.type));
    if (types.size === 1 && types.has("yoga")) {
      setServicesWithCategories(false); // One title for only yoga items
    } else {
      setServicesWithCategories(true); // Both subtitles visible
    }
  };

  // Toggle helper text when icon is clicked
  const handleHelpClick = (title: string) => {
    setActiveHelpTitle((prev) => (prev === title ? null : title));
  };

  const serviceContainerSxProps = {
    boxShadow: `0px 4px 8px ${theme.palette.overlay.dark}`,
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: theme.palette.white.regular,
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "100%",
    minHeight: { xs: "initial", md: "360px" },
    maxWidth: { xs: "300px", sm: "400px", md: "initial" },
  };
  const serviceContentSxProps = {
    display: "flex",
    flexDirection: "column",
    padding: 2,
    flexGrow: 1,
    "& > *": {
      marginBottom: 2,
    },
    "& > * p": {
      paddingRight: 2,
    },
    "& > * > svg": {
      color: theme.palette.orange.main,
      marginRight: 1,
    },
  };
  const subtitlesSxProps = {
    fontFamily: "Dahlia, Arial, sans-serif",
    textAlign: "center",
    fontSize: { xs: "2.25rem", md: "3rem" },
  };

  const addServiceItem = (service: Service) => {
    return (
      <Box
        key={service.serviceTitle}
        sx={{
          ...serviceContainerSxProps,
          ...(service.type === "massage" && {
            minHeight: { xs: "initial", md: "260px" },
          }),
        }}
      >
        <LazyLoadBackground
          imgURL={service.image.imageUrl}
          imgSize={{ width: 400, height: 400 }}
          alt={service.image.alt}
          hotspot={service.image.hotspot}
          sx={{
            minWidth: { xs: "300px", sm: "400px" },
            width: { sm: "400px" },
            height: { xs: "400px", md: "auto" },
          }}
        />
        <Box sx={serviceContentSxProps}>
          <Typography
            variant="h6"
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            {service.serviceTitle}
          </Typography>

          <Box display="flex">
            <InfoIcon />
            <Typography>{service.description}</Typography>
          </Box>

          {service.type === "yoga" && (
            <Box display="flex">
              <SpeedIcon />
              <Box display="flex" flexDirection={"column"}>
                <Typography>{service.intensity}</Typography>
                {/* Conditional FormHelperText that appears after clicking the help icon */}
                <FormHelperText
                  sx={{
                    color: theme.palette.primary.main,
                    height: activeHelpTitle === service.serviceTitle ? 40 : 0,
                    opacity: activeHelpTitle === service.serviceTitle ? 1 : 0,
                    transition: "height 0.3s ease-out, opacity 0.3s ease-out",
                    overflow: "hidden",
                  }}
                >
                  {t("intensityExplanation")}
                </FormHelperText>
              </Box>

              {/* Toggle intensity explanation visibility */}
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "auto",
                }}
              >
                {/* IconButton for mobile and desktop */}
                <IconButton
                  aria-label={`${t("ariaLabels.intensityExplanationToggle")}`}
                  onClick={() => handleHelpClick(service.serviceTitle)}
                  sx={{
                    padding: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginLeft: "auto",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    height: "fit-content",
                  }}
                >
                  <HelpOutlineIcon sx={{ color: theme.palette.orange.main }} />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box display="flex">
            <TimerIcon />
            <Typography>{service.duration}</Typography>
          </Box>

          {service.type === "yoga" && (
            <Box display="flex">
              <PeopleIcon />
              <Typography>{service.maxAttendees}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      gap={6}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      maxWidth={{ xs: 1000 }}
      zIndex={2}
    >
      {servicesWithCategories ? (
        <>
          <Typography variant="h2" sx={subtitlesSxProps}>
            {t("theServicesIOffer")} – {t("yogaTitle")}
          </Typography>
          {data.map((service) => {
            return service.type === "yoga" ? addServiceItem(service) : <></>;
          })}

          <Typography
            variant="h2"
            sx={{
              paddingTop: 6,
              ...subtitlesSxProps,
            }}
          >
            {t("theServicesIOffer")} – {t("massageTitle")}
          </Typography>
          {data.map((service) => {
            return service.type === "massage" ? addServiceItem(service) : <></>;
          })}
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Dahlia, Arial, sans-serif",
              zIndex: 1,
              paddingBottom: 4,
              textAlign: "center",
            }}
          >
            {t("theServicesIOffer")}
          </Typography>
          {data.map((service) => addServiceItem(service))}
        </>
      )}
      <Button
        variant="contained"
        sx={{ margin: "0 auto", marginTop: 4 }}
        component={"a"}
        href="https://vello.fi/wellinyoga/service"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${t("ariaLabels.bookInVello")}`}
      >
        {t("bookInVello")}
        <OpenInNewIcon sx={{ fontSize: "12px", paddingLeft: 1 }} />
      </Button>
    </Box>
  );
};

export default ServicesList;
